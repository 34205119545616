<template>
  <v-dialog width="40%" height="70%" v-model="show" @click:outside="closeDialog" scrollable>
    <div class="tw-bg-white tw-py-4 tw-px-6">
      <div class="tw-flex tw-items-center tw-justify-between">
        <div class="tw-text-2xl tw-p-2">Finalizar Conversa por <span class="primary-text">{{ selectedFinishedReason.name
        }}</span></div>
        <v-btn @click="closeDialog()" elevation="0" color="#fff" class="tw-mr-2 close-btn tw-rounded-full">
          <close-icon fillColor="#515151" :size=22 />
        </v-btn>
      </div>
      <div>
        <span class="default-text">Se você confirmar este diálogo, será enviada a seguinte mensagem
          para o chat: </span>
      </div>
      <div>
        <v-textarea v-model="finishedMessage" no-resize clearable outlined clear-icon="mdi-close-circle"></v-textarea>
      </div>
      <div v-if="!validationMsg" class="tw--mt-6 tw-text-sm tw-text-red-500 tw-font-medium">
        Este tipo de menssagem é inválida para ser mandada.
      </div>
      <div class="tw-ml-auto tw-w-56 tw-pb-2">
        <v-btn dark depressed @click="confirm()" color="blue">Confirmar</v-btn>
        <v-btn text color="blue" class="tw-ml-2" @click="closeDialog()">Cancelar</v-btn>
      </div>
    </div>
  </v-dialog>
</template>
  
<script setup>
import CloseIcon from 'vue-material-design-icons/Close.vue';
</script>
  
<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } =
  createNamespacedHelpers('chat');
export default {
  props: { value: Boolean, selectedChat: Object, selectedFinishedReason: Object },
  data() {
    return {
      finishedMessage: this.selectedFinishedReason.message,
      validationMsg: true,
    };
  },
  methods: {
    closeDialog() {
      this.$emit('close', false);
    },
    confirm() {
      let oldMsg = this.selectedFinishedReason.message
      if (this.finishedMessage && this.finishedMessage.trim().length != 0 || this.finishedMessage == '') {
        this.validationMsg = true;
        this.selectedFinishedReason.message = this.finishedMessage;
        this.$socket.client.emit('finishedChatting', this.selectedChat, this.selectedFinishedReason);
        this.selectedFinishedReason.message = oldMsg
        this.closeDialog();
      } else { this.validationMsg = false }

    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
  
<style>
.subtitle {
  color: #949494;
}

.mdi-close-circle::before {
  margin-left: 16px;
}

.primary-text {
  color: #007aff;
  font-weight: 600;
}

.default-text {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #949494;
  letter-spacing: -0.7px;
}
</style>
  