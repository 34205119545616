<template>
  <v-dialog :width="sas" :height="sas" v-model="show" @click:outside="closeDialog" scrollable>
    <!-- <div style="max-width: 100%;" class="tw-h-full tw-flex tw-justify-center" @click="teste()"> -->
    <img v-if="isZoom" class="zoom-out" @click="teste()" :src="selectedImage" alt="aa">
    <img v-else class="zoom-in" @click="teste()" :src="selectedImage" alt="aa">
    <!-- </div> -->
  </v-dialog>
</template>
  
<script setup>
import CloseIcon from 'vue-material-design-icons/Close.vue';
</script>
  
<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } =
  createNamespacedHelpers('chat');
export default {
  props: { value: Boolean, selectedChat: Object, selectedImage: String },
  data() {
    return {
      isZoom: false,
      sas: "40%"
    };
  },
  methods: {
    closeDialog() {
      this.$emit('close', false);
    },
    teste() {
      if(this.isZoom){
        this.sas = "40%";
      }else{
        this.sas = "70%";  
      }
      this.isZoom = !this.isZoom;
    },
    confirm() {
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
  
<style>
.img-size {
  max-width: auto;
  max-height: auto;
}

.mdi-close-circle::before {
  margin-left: 16px;
}
.zoom-in{
  cursor: zoom-in;
  max-height: 500px;
  max-width: 600px;
}
.zoom-out{
  cursor: zoom-out;
  max-height:100%;
  max-width: 100%;
}
</style>
  