<template>
  <v-dialog scrollable max-width="950" v-model="show" @click:outside="closeDialog">
    <v-card class="dialogCard custom-scroll" :loading="loading">
      <div class="d-flex px-9 pt-5 pb-6">
        <v-card-title class="pa-0 pb-3 dialogTitle">Novo atendimento</v-card-title>
        <v-spacer></v-spacer>
        <v-btn fab small icon @click="$emit('close')">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </div>
      <v-card-text class="custom-scroll">
        <ValidationObserver ref="observer">
          <v-form @submit.prevent="saveAttendence" ref="form">
            <v-row>
              <v-col cols="7">
                <v-card-subtitle class="pl-0 pb-2">Nome</v-card-subtitle>
                <v-text-field v-if="selectedChat" v-model="clientInfo.name" autofocus dense outlined
                  hide-details="auto"></v-text-field>
                <v-combobox v-else autofocus dense outlined :items="clientsArray" lazy-rules hide-details="auto"
                  no-data-text="Nenhuma informação disponível" :value="clientInfo" @input="changeClient" return-object
                  item-text="name">
                </v-combobox>

                <ValidationProvider rules="isNotBlank" v-slot="v">
                  <v-text-field v-model="clientInfo.name" class="form-control disabled"
                    style="display: none"></v-text-field>
                  <span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </v-col>
              <v-col cols="5">
                <div class="d-flex">
                  <v-card-subtitle class="pl-0 pb-2">Contato</v-card-subtitle>
                  <v-spacer></v-spacer>
                  <v-checkbox label="Whatsapp" hide-details="auto" style="lineheight: 0rem; margin-top: 10px"
                    v-model="clientInfo.hasWhats" :disabled="!!clientInfo.id"></v-checkbox>
                </div>
                <ValidationProvider rules="isNotBlank|isContactValid" v-slot="v">
                  <v-text-field outlined lazy-rules dense hide-details="auto"
                    no-data-text="Nenhuma informação disponível" v-model="clientInfo.contact" v-mask="phoneMask"
                    :disabled="!!clientInfo.id"></v-text-field>
                  <span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row v-if="dialogMode">
              <v-col cols="7">
                <v-card-subtitle class="pl-0 pb-2 pt-0">E-mail</v-card-subtitle>
                <ValidationProvider rules="isEmailValid" v-slot="v">
                  <v-text-field dense outlined hide-details="auto" lazy-rules v-model="clientInfo.email"
                    :disabled="!!clientInfo.id"></v-text-field>
                  <span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </v-col>
              <v-col cols="5">
                <v-card-subtitle class="pl-0 pb-2 pt-0">CPF</v-card-subtitle>
                <ValidationProvider v-slot="v" rules="isCPFValid">
                  <v-text-field dense outlined hide-details="auto" lazy-rules v-model="clientInfo.CPF"
                    v-mask="'###.###.###-##'" :disabled="!!clientInfo.id"></v-text-field>
                  <span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row v-if="dialogMode">
              <v-col cols="7">
                <v-row>
                  <v-col cols="4">
                    <v-card-subtitle class="pl-0 pb-2 pt-0">CEP</v-card-subtitle>
                    <ValidationProvider rules="isCEPValid" v-slot="v">
                      <v-text-field dense outlined hide-details="auto" lazy-rules v-model="clientInfo.clientAddress.CEP"
                        v-mask="'##.###-###'" @blur="autocompleteAddress(v.errors)"
                        :disabled="!!clientInfo.id"></v-text-field>
                      <span class="form-error">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="8">
                    <v-card-subtitle class="pl-0 pb-2 pt-0">Rua</v-card-subtitle>
                    <ValidationProvider v-slot="v">
                      <v-text-field dense lazy-rules outlined hide-details="auto"
                        v-model="clientInfo.clientAddress.street" :disabled="!!clientInfo.id"></v-text-field>
                      <span class="form-error">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="5">
                <v-row>
                  <v-col cols="4">
                    <v-card-subtitle class="pl-0 pb-2 pt-0">N°</v-card-subtitle>
                    <ValidationProvider v-slot="v">
                      <v-text-field dense lazy-rules outlined hide-details="auto" type="number" @keydown.69="preventE"
                        v-model.number="clientInfo.clientAddress.number" id="numberField"
                        :disabled="!!clientInfo.id"></v-text-field>
                      <span class="form-error">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="8">
                    <v-card-subtitle class="pl-0 pb-2 pt-0">Complemento</v-card-subtitle>
                    <ValidationProvider v-slot="v">
                      <v-text-field lazy-rules dense outlined hide-details="auto"
                        v-model="clientInfo.clientAddress.complement" :disabled="!!clientInfo.id"></v-text-field>
                      <span class="form-error">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row v-if="dialogMode">
              <v-col cols="7">
                <v-row>
                  <v-col cols="4">
                    <v-card-subtitle class="pl-0 pb-2 pt-0">Bairro</v-card-subtitle>
                    <ValidationProvider v-slot="v">
                      <v-text-field dense outlined hide-details="auto" lazy-rules
                        v-model="clientInfo.clientAddress.neighbourhood" :disabled="!!clientInfo.id"></v-text-field>
                      <span class="form-error">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="8">
                    <v-card-subtitle class="pl-0 pb-2 pt-0">Cidade</v-card-subtitle>
                    <ValidationProvider v-slot="v">
                      <v-text-field dense outlined hide-details="auto" lazy-rules
                        v-model="clientInfo.clientAddress.city" :disabled="!!clientInfo.id"></v-text-field>
                      <span class="form-error">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="5">
                <v-card-subtitle class="pl-0 pb-2 pt-0">Estado</v-card-subtitle>
                <ValidationProvider v-slot="v">
                  <v-text-field dense outlined hide-details="auto" lazy-rules v-model="clientInfo.clientAddress.state"
                    :disabled="!!clientInfo.id"></v-text-field>
                  <span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="7">
                <v-card-subtitle class="pl-0 pb-2 pt-0">Projetista responsável</v-card-subtitle><v-select outlined dense
                  hide-details="auto" :items="designers" v-model="designer" no-data-text="Nenhuma informação disponível"
                  item-text="name" return-object></v-select>
                <ValidationProvider rules="isNotBlank" v-slot="v">
                  <v-text-field v-model="designer.name" class="form-control disabled"
                    style="display: none"></v-text-field>
                  <span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </v-col>
              <!-- <v-col cols="5">
                <v-row>
                  <v-col cols="6">
                    <v-card-subtitle class="pl-0 pb-2 pt-0"
                      >Início audição</v-card-subtitle
                    >
                    
                    <datetime
                      type="datetime"
                      v-model="startDate"
                      :phrases="{ ok: 'Ok', cancel: 'Cancelar' }"
                      :week-start="7"
                      :max-datetime="endDate"
                      auto
                    >
                    </datetime>
                    <ValidationProvider v-slot="v">
                  <v-text-field
                    v-model="startDate"
                    class="form-control disabled"
                    style="display: none"
                  ></v-text-field>
                  <span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
                  </v-col>
                  <v-col cols="6">
                    <v-card-subtitle class="pl-0 pb-2 pt-0"
                      >Final da audição</v-card-subtitle
                    >
                    
                    <datetime
                      type="datetime"
                      v-model="endDate"
                      :phrases="{ ok: 'Ok', cancel: 'Cancelar' }"
                      :week-start="7"
                      :min-datetime="startDate"
                      auto
                    >
                    </datetime>
                    <ValidationProvider v-slot="v">
                  <v-text-field
                    v-model="endDate"
                    class="form-control disabled"
                    style="display: none"
                  ></v-text-field>
                  <span class="form-error">{{ v.errors[0] }}</span>
                </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col> -->
            </v-row>
          </v-form>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions>
        <div>Rápido</div>
        <v-switch hide-details="auto" v-model="dialogMode" class="pl-3 pr-0"></v-switch>
        <div>Completo</div>
        <v-spacer></v-spacer>
        <v-btn text color="blue" @click="closeDialog">Cancelar</v-btn>
        <v-btn dark depressed color="blue" class="saveButton" @click="submitForm()">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CLIENT_MASKS_MIXIN from '@/utils/mixins/masks.js';

import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';

import { createNamespacedHelpers } from 'vuex';

const {
  mapState: mapStateClients,
  mapGetters: mapGettersClients,
} = createNamespacedHelpers('clients');
const {
  mapState: mapStateConfig,
} = createNamespacedHelpers('config');

export default {
  mixins: [CLIENT_MASKS_MIXIN],
  // components: {
  //   datetime: Datetime,
  // },
  props: { value: Boolean, selectedClient: Number, selectedChat: Object, isLoading: { type: Boolean, default: false } },
  data() {
    return {
      loading: false,
      testChatId: 0,
      clientInfo: {
        name: '',
        CPF: '',
        email: '',
        contact: '',
        hasWhats: true,
        clientAddress: {
          CEP: '',
          street: '',
          number: '',
          complement: '',
          neighbourhood: '',
          city: '',
          state: '',
          clientId: null,
        },
      },
      blankClientInfo: {
        name: '',
        CPF: '',
        email: '',
        contact: '',
        hasWhats: false,
        clientAddress: {
          CEP: '',
          street: '',
          number: '',
          complement: '',
          neighbourhood: '',
          city: '',
          state: '',
          clientId: null,
        },
      },

      designer: '',
      startDate: null,
      endDate: null,
      clientId: null,
      designers: [],
      dialogMode: false,
      clientsArray: [],
    };
  },
  watch: {
    isLoading(val) {
      this.loading = val
    }
  },
  mounted() {
    if (this.selectedChat) {
      this.designer = this.loggedUser;
      let arr = this.selectedChat.number.substring(4).split('');
      arr.splice(4, 0, "9");
      let numToFind = arr.join("");
      numToFind = numToFind.replaceAll("(", "");
      numToFind = numToFind.replaceAll(")", "");
      numToFind = numToFind.replaceAll("-", "");
      numToFind = numToFind.replaceAll(" ", "");
      const client = { ...this.clients.find((el) => el.contact == numToFind) }
      if (client.id) {
        this.clientInfo = client;
      } else {
        const num = this.selectedChat.number.substring(4);
        let arrNum = num.split("");
        arrNum.splice(4, 0, '9');
        arrNum = arrNum.join("");
        this.clientInfo.contact = arrNum;
        if (this.selectedChat.name) {
          this.clientInfo.name = this.selectedChat.name;
        } else if (this.selectedChat.pushname) {
          this.clientInfo.name = this.selectedChat.pushname;
        } else {
          this.clientInfo.name = null;
        }
        this.clientInfo.hasWhats = true;
      }
    }
  },
  methods: {
    async autocompleteAddress(errors) {
      this.loading = true;
      if (errors.length == 0) {
        try {
          const address = await this.findAddressByZipCode(
            this.clientInfo.clientAddress.CEP
          );
          this.clientInfo.clientAddress.city = address.city;
          this.clientInfo.clientAddress.neighbourhood = address.neighborhood;
          this.clientInfo.clientAddress.state = address.state;
          this.clientInfo.clientAddress.street = address.street;
          document.querySelector('#numberField').focus();
        } catch (error) {
          this.loading = false;
        }
      }
      this.loading = false;
    },
    preventE(event) {
      event.preventDefault();
    },
    inputClient(event) {
      if (!event.id) {
        this.clientInfo = this.blankClientInfo;
      }
    },
    updateClient(event) {
      if (event.id) {
        this.clientInfo = event;
        this.clientId = event.id;
      } else {
        this.clientInfo.name = event;
      }
    },
    changeClient(event) {
      if (!this.selectedChat) {
        if (event.id) {
          this.clientInfo = event;
        } else {
          this.clientInfo = this.blankClientInfo;
          this.clientInfo.name = event;
        }
      }
      else {
        let a;
        a = 0;
        // this.clientInfo.name = event;
      }
    },
    async saveAttendence() {
      if (this.newArray.clientInfo.hasWhats) {
        let _serialized = this.newArray.clientInfo.contact;
        _serialized = _serialized.replace('(', '');
        _serialized = _serialized.replace(')', '');
        _serialized = _serialized.replace('-', '');
        _serialized = _serialized.replace(' ', '');
        const formattedNumber = _serialized.split('');
        if (formattedNumber.length > 10) {
          formattedNumber.splice(2, 1);
        }
        _serialized = formattedNumber.toString();
        _serialized = _serialized.replaceAll(',', '');
        _serialized = '55' + _serialized + '@c.us';
        this.newArray.chat = { _serialized };
        this.loading = true;
        await this.$refs.observer.validate();
        if (this.$refs.observer._data.flags.valid) {
          this.$emit('saveAttendence', this.newArray);
        } else {
          this.loading = false;
        }
      }
      else {
        this.loading = true;
        await this.$refs.observer.validate();
        if (this.$refs.observer._data.flags.valid) {
          this.$emit('saveAttendence', this.newArray);
        } else {
          this.loading = false;
        }
      }
    },
    // fazendo
    submitForm() {
      this.loading = true;
      this.$refs.form.$el.requestSubmit();
    },
    closeDialog() {
      this.$emit('close', false);
    },
    saveForm() {
      this.ValidateForm();
    },
    ValidateForm() { },
  },
  computed: {
    ...mapGettersClients(['getClient']),
    ...mapStateClients(['clients']),
    ...mapStateConfig(['users', 'loggedUser']),
    phoneMask() {
      return this.getPhoneMask(this.clientInfo.contact);
    },
    newArray() {
      return {
        clientInfo: this.clientInfo,
        attendenceInfo: {
          userId: this.designer.id,
          clientId: this.clientInfo.id,
          statusId: 1,
          startTime: this.startDate,
          endTime: this.endDate,
          registerDate: new Date(),
        },
        user: this.designer,
      };
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  async created() {
    this.clientsArray = Object.values(this.clients);
    this.designers = Object.values(this.users);
    if (this.selectedClient) {
      this.clientInfo = this.getClient(this.selectedClient);
    }
  },
};
</script>

<style>
.custom-scroll::-webkit-scrollbar {
  border-radius: 30px;
  width: 8px;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 30px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #f0f0f0;
  border-radius: 30px;
}

.dialogCard .mdi-checkbox-marked::before {
  font-weight: 900;
  content: url('https://api.iconify.design/mdi-checkbox-marked-circle.svg?color=%23007aff&height=24');
  padding-top: 3px;
}

.dialogCard .mdi-checkbox-blank-outline::before {
  font-weight: 900;
  padding-top: 3px;
  content: url('https://api.iconify.design/mdi-checkbox-blank-circle-outline.svg?color=rgba(0%2C0%2C0%2C0.6)&height=24');
}

.dialogCard .vdatetime {
  border: 1px solid #e8e8e8;
  height: 40px;
  border-radius: 6px;
}

.dialogCard .dialogTitle {
  font-size: 22px;
  font-weight: 600;
  color: #575962;
}

.v-dialog.v-dialog--active {
  border-radius: 22px !important;
}

.dialogCard {
  border-radius: 22px !important;
  overflow: auto;
  max-height: 100vh;
}

.dialogCard .v-text-field--outlined fieldset {
  color: #e8e8e8 !important;
}

.dialogCard>div>form>.row>.col {
  padding-bottom: 0px;
  padding-left: 0px;
}

.dialogCard>div>form>.row>.col>.row>.col:nth-child(2) {
  padding-left: 0px;
}

.dialogCard .vdatetime-input {
  height: 100%;
  width: 100%;
  padding: 5px;
}

.dialogCard .vdatetime-input:hover {
  cursor: pointer;
}

.dialogCard .saveButton {
  border-radius: 10px;
}
</style>
