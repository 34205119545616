<template>
  <v-dialog width="40%" persistent v-model="show" @click:outside="closeDialog" scrollable>
    <div class="tw-h-full tw-w-full tw-bg-white">
      <div class="headerContacts">
        <div class="tw-w-full tw-flex tw-gap-4 tw-text-white tw-p-4 tw-items-center tw-bg-green-700">
          <v-btn @click="closeDialog" elevation="0" class="close-btn tw-bg-green-700 tw-rounded-full">
            <CloseIcon fill-color="#f0f0f0" />
          </v-btn>
          <div class="tw-text-2xl">Enviar Contato</div>
        </div>
        <div class="tw-w-full tw-bg-white tw-h-full tw-p-4">
          <div class="input-search tw-flex tw-items-center tw-rounded-full tw-bg-white">
            <MagnifyIcon fillColor="#179788" :size="18" class="tw-ml-2 tw-w-2" />
            <input class="tw-mx-5 tw-my-1 tw-w-full tw-py-0.5 tw-text-gray-700 tw-bg-white" autocomplete="off" id="search"
              type="text" v-model="findContact" placeholder="Pesquisar contato" />
          </div>
          <div class="text-green tw-px-4 tw-mt-8">Contatos</div>
        </div>
      </div>

      <div class="tw-px-6 listContacts custom-scroll">
        <ul v-for="(contact, index) in filterContacts()" @click="closeDialog()"
          class="tw-flex tw-items-start tw-gap-4 tw-py-3 tw-pl-3 tw-border-t-2 tw-w-full contactHover">
          <div v-if="contact.profilePic">
            <img class="tw-rounded-full tw-mr-4 tw-w-12" :src="contact.profilePic" />
          </div>
          <div v-else-if="!contact.profilePic && contact.isGroup" class="tw-rounded-full tw-p-2 tw-bg-gray-400 tw-mr-2">
            <group-icon iconSize="32" iconColor="#d3d3d3" />
          </div>
          <div v-else-if="!contact.profilePic && !contact.isGroup" class="tw-rounded-full tw-p-2 tw-bg-gray-400 tw-mr-2">
            <contact-icon iconSize="32" iconColor="#000000" />
          </div>
          <li>
            <div>
              {{ contact.name }}
            </div>
            <div class="number-class">
              {{ formatedNumber(contact._serialized) }}
            </div>
          </li>
        </ul>
        <div v-if="!filterContacts().length" class="tw-text-center tw-mt-8 tw-text-gray-400 tw-text-lg tw-font-light">
          Nenhum contato encontrado
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('chat');
const { mapActions } = createNamespacedHelpers('chat');

export default {
  props: { value: Boolean, attendence: Object },
  data() {
    return {
      findContact: '',
    };
  },
  methods: {
    closeDialog() {
      this.$emit('close', false);
    },
    formatedNumber(number){
      number = '+' + number;
      let arrayNum = number.split('');
      arrayNum.splice(3, 0, ' ');
      arrayNum.splice(6, 0, ' ');
      arrayNum.splice(11, 0, '-');
      arrayNum.splice(16, 5, '');
      let num = arrayNum.toString();
      num = num.replaceAll(',', '');
      return num
    },
    filterContacts() {
      let array = [];
      array = this.contacts;
      array = array.filter((el) => {
        if (el.name) {
          return el.name.toLowerCase().includes(this.findContact.toLowerCase()) && !el.isGroup;
        }
        else if(el.pushname){
          return el.pushname.toLowerCase().includes(this.findContact.toLowerCase()) && !el.isGroup;
        }
      }
      );
      array = array.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
      return array;
    },
  },
  computed: {
    ...mapState(['contacts']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<script setup>
import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';
import CloseIcon from 'vue-material-design-icons/Close.vue';
</script>

<style>
#search {
  outline: none;
}

.contactHover:hover {
  background-color: #f0f0f0;
  transform: scale(1.03);
  border-radius: 6px;
}

.listContacts {
  height: calc(600px - 210px) !important;
  overflow-y: auto;
}

.close-btn {
  min-width: 36px !important;
  width: 36px;
  border-radius: 36px;
  height: 36px;
}

.input-search {
  border: 1px solid #179788;
}

.text-green {
  color: #48beb0;
  font-size: 18px;
}

.number-class{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #89969e;
}

.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}
</style>
