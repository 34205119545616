<template>
    <v-dialog width="40%" height="70%" v-model="show" @click:outside="closeDialog" scrollable>
        <div class="default-text tw-bg-white tw-py-4 tw-px-6 tw-flex tw-justify-center tw-flex-col">
            <div>
                A mensagem selecionada para ser respondida, <br> <span class="primary-text">não pode ser respondida</span>.
            </div>
            <div class="tw-w-24 tw-ml-auto">
                <v-btn dark depressed @click="closeDialog()" color="blue">Ok</v-btn>
            </div>
        </div>

    </v-dialog>
</template>
    
<script setup>
import CloseIcon from 'vue-material-design-icons/Close.vue';
</script>
    
<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } =
    createNamespacedHelpers('chat');
export default {
    props: { value: Boolean },
    data() {
        return {
        };
    },
    methods: {
        ...mapActions(['confirmQuotedMsgModal']),
        closeDialog() {
            this.confirmQuotedMsgModal();
            this.$emit('close', false);
        },
    },
    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>
    
<style>
.subtitle {
    color: #949494;
}

.mdi-close-circle::before {
    margin-left: 16px;
}

.primary-text {
    color: #007aff;
    font-weight: 600;
}

.default-text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    letter-spacing: -0.7px;
}
</style>
    